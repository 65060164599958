@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ad-card-empty-state {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.8)
    ),
    url('images/ad-card-empty-state.png');
}

.react-datepicker-wrapper {
  max-width: 25%;
}
